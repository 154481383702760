import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('masthead', function () {
    return {
      openMenu: false,
      toggleMenu() {
        this.openMenu = !this.openMenu
      },
      closeMenu() {
        this.openMenu = false
      },
      toggle() {
        return {
          ['@click.prevent']: this.toggleMenu
        }
      },
      menu: {
        [':class']: function() {
          return {
            'max-md:translate-x-full': !this.openMenu,
          }
        },
      },
      init() {
        this.$watch('openMenu', (value) => {
          this.$store.app.toggleOverflow(!value, true)
          this.$store.app.hasActiveOverlay = value
        })
      }
    }
  })
})()