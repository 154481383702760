import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('slider', function (conf = {}) {
    return {
      instance: null,
      async load(controller) {
        const root = this.$root
        const slider = this.$refs['slider'] || this.$el
        const [Swiper, modules] =
          await Promise.all([
            import('swiper').then((mod) => mod.default),
            import('swiper/modules').then((mod) => [
              mod.Autoplay,
              mod.Navigation,
              mod.Pagination,
              mod.Thumbs,
            ]),
            import('swiper/css'),
          ])
        const [next, prev] = [
          this.$refs['next'],
          this.$refs['prev']
        ]
        const pagination = this.$refs['pagination']

        if (conf?.pagination) {
          conf.pagination.clickable = true
          conf.pagination.clickableClass = 'cursor-pointer'

          if (conf.pagination?.renderBullet) {
            conf.pagination.renderBullet = new Function('i', 'className', 'return ' + conf.pagination.renderBullet)
          }

          if (conf.pagination?.el) {
            conf.pagination.el = root.querySelector(conf.pagination.el)
          }

          if (pagination) {
            conf.pagination.el = pagination
          }
        }

        this.instance = new Swiper(slider, {
          modules,
          ...((next && prev) && {
            navigation: {
              nextEl: next,
              prevEl: prev,
              disabledClass: 'opacity-50'
            },
          }),
          ...(controller && {
            thumbs: {
              swiper: await controller,
            },
          }),
          ...conf,
        })

        return this.instance
      },
    }
  })
})()
