import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'

import 'modules/measure'
import 'modules/form'
import 'modules/accordion'
import 'modules/tabs'
import 'modules/slider'
import 'modules/lightbox'
import 'modules/scroll-rotator'

import 'components/masthead'

Alpine.store('app', {
  officeSwitcherOpen: false,
  hasActiveOverlay: false,
  isScrolling: false,
  scrollDirection: 'down',
  toggleOverflow(flag = false, responsive = false) {
    if (responsive) document.documentElement.classList.toggle('max-md:overflow-hidden', !flag)
    else document.documentElement.style.overflow = flag ? null : 'hidden'
  },
  toggleOfficeSelect() {
    this.officeSwitcherOpen = !this.officeSwitcherOpen
    this.toggleOverflow(!this.officeSwitcherOpen)
  }
})

let prevScroll = window.scrollY
const scrollThreshold = 100
window.addEventListener('scroll', () => {
  if (window.scrollY > scrollThreshold && !Alpine.store('app').isScrolling) {
    Alpine.store('app').isScrolling = true
    document.documentElement.style.setProperty('--is-scrolling', `initial`)
  }
  if (window.scrollY <= scrollThreshold && Alpine.store('app').isScrolling) {
    Alpine.store('app').isScrolling = false
    document.documentElement.style.removeProperty('--is-scrolling')
  }

  if (Alpine.store('app').isScrolling) {
    if (window.scrollY >= prevScroll) Alpine.store('app').scrollDirection = 'down'
    else Alpine.store('app').scrollDirection = 'up'
  }
  else {
    Alpine.store('app').scrollDirection = 'down'
  }

  prevScroll = window.scrollY
})

document.addEventListener('click', (e) => {
  if (e.target.closest('a[href*="#office-toggle"]')) {
    e.preventDefault()
    Alpine.store('app').toggleOfficeSelect()
  }
})

Alpine.plugin(intersect)

window.Alpine = Alpine
Alpine.start()